import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/activity';
const api = getApi(BACKEND);

export const getLogInActivityList = async (queryString: string) => {
  return await api.get<{ rows: ActivityLogInAsVendorRepListItem[]; count: number }>(
    `/login-as-vendor-rep${queryString ? '?' : ''}${queryString}`,
  );
};
