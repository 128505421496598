import axios from 'axios';

import { getBackendUrl } from '../utils/getBackendUrl';

export const refreshUser = async () => {
  const url = getBackendUrl() + '/token';
  return await axios.get(url, { withCredentials: true });
};

export const getApi = (backendUrl: string) => {
  const api = axios.create({
    baseURL: backendUrl,
    withCredentials: true,
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401 && location.pathname !== '/sign-in') {
        location.href = '/sign-in'; // reloading page to have clean state and avoid potential bugs
      }

      return Promise.reject(error);
    },
  );

  return api;
};

export default axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`,
  headers: {
    'Content-type': 'application/json',
  },
});
