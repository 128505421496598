import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { validateUser } from '../../store/user';
import { Copyright } from '../../components/common/Copyright';
import Link from '../../components/common/link';
import { useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import { useQuery } from '../../utils/common';
import VpButton from '../../components/common/VpButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { REPRESENTATIVE_TYPES } from '../../constants/application';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SignInToolbar } from '../../components/common/SignInToolbar';

type SignInFormValues = {
  email: string;
  password: string;
  remember: boolean;
  type?: string | undefined;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alertBlock: {
    margin: '20px 0',
    width: '100%',
  },
  selectAcc: {
    display: 'inline-block',
    marginTop: '20px',
    textAlign: 'center',
  },
}));

const userErrorSelector = (state: AppStoreType) => state.user;

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const intl = useIntl();
  const { error } = useSelector<AppStoreType, ReturnType<typeof userErrorSelector>>(
    userErrorSelector,
  );
  const dispatch = useDispatch();
  const isPasswordChangedSuccess = !!query.get('passwordChangedSuccessfully');
  const emailFromChanged = query.get('email');

  const formMethods = useForm<SignInFormValues>({
    defaultValues: {
      email: emailFromChanged || '',
      password: '',
      remember: false,
      type: '',
    },
  });
  const { register, handleSubmit, watch, setValue } = formMethods;
  const email = watch('email');

  const passwordCredentialsAPI = !!window?.PasswordCredential;
  const [isTypeSelection, setIsTypeSelection] = React.useState(false);
  const [isTypeError, setIsTypeError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    if (passwordCredentialsAPI) {
      try {
        navigator.credentials
          ?.preventSilentAccess()
          .then(() => {
            navigator.credentials.get({ password: true }).then((cred) => {
              if (cred?.password && cred?.id) {
                submit({
                  remember: false,
                  email: cred.id,
                  password: cred.password,
                });
              }
            });
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
      } catch {
        // do nothing
      }
    }
  }, [passwordCredentialsAPI]);

  const submit = async (data: SignInFormValues) => {
    const { email, password, remember, type } = data;
    const user = { email, password, ...(type && { type }) };

    if (isTypeSelection && !type) {
      setIsTypeError(true);
      return false;
    }

    try {
      await dispatch(validateUser(user));
    } catch (e) {
      if (e?.code === 409) setIsTypeSelection(true);
      if (e?.errorType === 'passExpired') {
        history.push('/change-expired-password?email=' + encodeURIComponent(email));
      }
      return;
    }
    if (remember) {
      await saveCredentials();
    }

    const loc = new URLSearchParams(location.search);
    const redirect = loc.get('redirect');

    history.push(redirect ? (redirect as string) : '/');
  };

  const saveCredentials = async () => {
    try {
      if (navigator.credentials && passwordCredentialsAPI) {
        const cred = await navigator.credentials.create({
          password: document.getElementById('sign-in-form') as HTMLFormElement,
        });
        if (cred) {
          await navigator.credentials.store(cred);
        } else {
          // eslint-disable-next-line no-console
          console.warn('unsupported browser');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed during save credentials', e);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <SignInToolbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar data-cy="avatar" className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography data-cy="sign-in-header" component="h1" variant="h5">
            {intl.formatMessage({ id: 'common.sign_in' })}
          </Typography>
          {isPasswordChangedSuccess && (
            <Alert className={classes.alertBlock} severity="success">
              <Typography component="div" variant="subtitle2">
                {intl.formatMessage({ id: 'common.password_changed_successfully' })}
              </Typography>
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <form
              id="sign-in-form"
              className={classes.form}
              onSubmit={handleSubmit(submit)}
              noValidate
            >
              <input type="hidden" value={email} id="id" name="id" />
              <input type="hidden" value={email} id="username" name="username" />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register}
                id="email"
                label={intl.formatMessage({ id: 'common.email_address' })}
                name="email"
                autoComplete="username"
                autoFocus
                data-cy="email-field"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register}
                name="password"
                label={intl.formatMessage({ id: 'common.password' })}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                data-cy="password-field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isTypeSelection && (
                <TextField
                  {...register({ name: 'type', required: true })}
                  className={classes.selectAcc}
                  variant="outlined"
                  required
                  fullWidth
                  select
                  label={intl.formatMessage({ id: 'common.select_representative' })}
                  name="type"
                  id="type"
                  data-cy="type-field"
                  defaultValue=""
                  error={isTypeError}
                  helperText={
                    isTypeError && intl.formatMessage({ id: 'common.representative_required' })
                  }
                  onChange={(e) => {
                    setIsTypeError(false);
                    setValue('type', e.target.value, { shouldDirty: true, shouldValidate: true });
                  }}
                >
                  {REPRESENTATIVE_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl.formatMessage({ id: option.label })}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {passwordCredentialsAPI && (
                <FormControlLabel
                  name="remember"
                  control={
                    <Checkbox
                      name="remember"
                      inputRef={register}
                      color="primary"
                      data-cy="remember-me-checkbox"
                    />
                  }
                  label={intl.formatMessage({ id: 'common.remember_me' })}
                />
              )}

              {error && (
                <Typography variant="subtitle2" color="error" data-cy="error-mesage">
                  {error}
                </Typography>
              )}
              <VpButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(submit)}
                data-cy="sign-in-button"
              >
                {intl.formatMessage({ id: 'common.sign_in' })}
              </VpButton>
              <Grid container>
                <Grid data-cy="password-reset-link" item xs>
                  <Link to="/forgot-password">
                    {intl.formatMessage({ id: 'common.forgot_password' })}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
