import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dot: {
    fontSize: '12px',
    marginRight: '5px',
  },
  dotSuccess: {
    color: theme.palette.success.main,
  },
  dotFailed: {
    color: theme.palette.action.disabled,
  },
  validationWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  validationTextSuccess: {
    color: theme.palette.success.contrastText,
  },
  disabled: {
    backgroundColor: theme.palette.action.selected,
  },
  alertHeader: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  alertBlock: {
    margin: '20px 0',
    width: '100%',
  },
  backToSignIn: {
    display: 'flex',
    alignItems: 'center',
  },
  backToSignInIcon: {
    marginRight: '5px',
  },
  selectAcc: {
    marginTop: '17px',
  },
}));
