import { AxiosResponse } from 'axios';
import { getBackendUrl } from '../utils/getBackendUrl';
// import { buildPaginationFilterQuery } from '../utils/common';
import { buildAGPaginationFilterQuery } from '../utils/common';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

import { getApi } from './httpCommon';

export type SignUpUserType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export type SignInUserType = {
  email: string;
  password: string;
};

export type LanguageType = {
  code: string;
};

type EmailSent = {
  email: string;
  uuid: string;
};

const BACKEND = getBackendUrl() + '/user';
const api = getApi(BACKEND);

export const signUpUser = async (user: SignUpUserType) => {
  return await api.post('/', { user });
};

export const createUser = async (user: CreateUserType) => {
  return await api.post<unknown, AxiosResponse<UserStoreType>>('/', user);
};

export const signInUser = async (user: SignInUserType) => {
  return await api.post('/login', user);
};

export const switchAccount = async (vendorUuid: string) => {
  return await api.post(`/switch/${vendorUuid}`);
};

export const logoutUser = async () => {
  return await api.delete('/logout');
};

export const logoutAsVendorUser = async () => {
  return await api.delete('/logout-as-user');
};

export const changeUserLanguage = async (languageType: LanguageType) => {
  return await api.post('/change-language', languageType);
};

export const getAllUsers = async (params: IGetRowsParams) => {
  return await api.get<PaginationUserListType>(`/usersList${buildAGPaginationFilterQuery(params)}`);
};

export const getUserOnEdit = async (uuid: string) => {
  return await api.get(`/${uuid}`);
};

export const updateUser = async (uuid: string, userObj: EditUserType) => {
  return await api.put(`/${uuid}`, userObj);
};

export const updateUserProfile = async (userObj: EditUserType) => {
  return await api.post('/update-profile', userObj);
};

export const fetchUserData = async () => {
  return await api.get<UserStoreType>('');
};

export const resetPassword = async (email: string, type?: string | undefined) => {
  return await api.post('/reset-password', { email, type });
};

export const changeUserEmail = async (token: string) => {
  return await api.post('/change-email', { token });
};

export const changeUserStatus = async (uuid: string, status: boolean) => {
  return await api.post(`/change-status/${uuid}`, { status });
};

export const changeUserPassword = async ({
  prevPassword,
  newPassword,
  token,
  email,
  type,
}: {
  newPassword: string;
  token?: string;
  prevPassword?: string;
  email?: string;
  type?: string | undefined;
}) => {
  return await api.post('/change-password', { newPassword, token, prevPassword, email, type });
};

export const bulkChangeUserStatus = async (usersUuids: string[], status: boolean) => {
  return await api.post('/bulk-change-status', { usersUuids, status });
};

export const bulkPasswordReset = async (usersUuids: string[]) => {
  return await api.post<{
    status: 'success' | 'fail' | 'partially';
    failedToSend: EmailSent[];
    sentEmails: EmailSent[];
  }>('/bulk-password-reset', { usersUuids });
};

export const deleteUser = async (userUuid: string) => {
  return await api.delete(`/${userUuid}`);
};
