import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import { GridApi, GridOptions } from '@ag-grid-community/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import React, { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { defaultColDef, useColumnTypes } from './defaultSettings';
import components from './components';
import filters from './filters';
import { useQuoteColumnTypes } from './settings/quote';
import { useEmailTemplatesColumnTypes } from './settings/emailTemplates';
import { useUserManagmentColumnTypes } from './settings/userManagment';
import { useDraftApplicationColumnTypes } from './settings/draftApplication';

export type RawDataGridPropsType = {
  onInit?: (api: GridApi) => unknown;
  children: React.ReactNode;
} & Partial<ComponentProps<typeof AgGridReact>>;

const BaseDataGrid = ({ onInit, children, ...props }: RawDataGridPropsType) => {
  const intl = useIntl();
  const columnTypes = useColumnTypes();
  const quoteColumnTypes = useQuoteColumnTypes();
  const emailTemplatesColumnTypes = useEmailTemplatesColumnTypes();
  const userManagmentColumnTypes = useUserManagmentColumnTypes();
  const draftApplicationColumnTypes = useDraftApplicationColumnTypes();

  const localeTextFunc: GridOptions['localeTextFunc'] = React.useCallback(
    (key, defaultMessage) => {
      return intl.formatMessage({ id: key, defaultMessage });
    },
    [intl],
  );

  return (
    <AgGridReact
      headerHeight={35}
      floatingFiltersHeight={35}
      defaultColDef={defaultColDef}
      columnTypes={{
        ...quoteColumnTypes,
        ...emailTemplatesColumnTypes,
        ...userManagmentColumnTypes,
        ...draftApplicationColumnTypes,
        ...columnTypes,
      }}
      frameworkComponents={{
        ...components,
        ...filters,
      }}
      onGridReady={(params) => onInit?.(params.api)}
      suppressRowClickSelection
      rowSelection="multiple"
      groupSelectsChildren
      localeTextFunc={localeTextFunc}
      suppressRowDeselection
      rowMultiSelectWithClick
      {...props}
    >
      {children}
    </AgGridReact>
  );
};

export default BaseDataGrid;
