import React, { useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import Loading from '../../components/common/Loading';
import useStyles from './styles';
import { generatePath, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { CURRENCY_TYPE, defaultCurrency } from '../../constants/currency';
import AlertModal from '../../components/common/alert-modal';
import HappyEnd from '../../components/common/HappyEnd';
import ApplicationError from '../../components/common/ApplicationError';
import { prepareApplicationDataToSend, useApplicationSections } from './applicationUtils';
import NewApplicationContext, {
  NewApplicationContextProps,
  NewApplicationLeaveListener,
  OnSaveListener,
} from './new-application.context';
import { NewApplicationConnectedProps } from './new-application.container';
import { getPublicRoute, isPublicRoute } from '../../utils/common';
import { EDIT_DRAFT_APPLICATION_PAGE, SIGN_IN_PAGE } from '../../constants/navigations';
import { Dropzone } from '../../components/common/VaultDropzone';
import { NEW_APPLICATION_PAGE } from '../../constants/navigations';
import NewApplicationSections from './new-application-sections';
import { FormProvider, useForm } from 'react-hook-form';
import ReactRouterPause from '@allpro/react-router-pause';
import { OnLeavePopup } from './on-leave-popup';
import useSafeSetState from '../../components/common/useSafeSetState';
import { Backdrop } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
const routesArray = ['/:vendorSiteUrl/:userSiteUrl', '/:vendorSiteUrl', ''];

export type ApplicationRouteParamsType = {
  applicationUuid?: string;
  vendorSiteUrl?: string;
  userSiteUrl?: string;
};

function NewApplication({
  prepareApplicationForm,
  preparePublicApplicationForm,
  resetApplication,
  getDraftApplication,
  application,
  loading,
  naicsList,
  createApplication,
  updateApplication,
  submitApplication,
  vendorReps,
  userUuid,
  isSiteApplicationDisabled,
}: NewApplicationConnectedProps) {
  const routeParams = useParams<ApplicationRouteParamsType>();
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const isPublicPage = React.useMemo(() => isPublicRoute(routeParams), [routeParams]);
  const [happyEnd, setHappyEnd] = useState(false);
  const [backdropLoader, setBackdropLoader] = useState(false);
  const [currency, setCurrency] = useState<CURRENCY_TYPE>(
    (application?.application.currency || defaultCurrency.name) as CURRENCY_TYPE,
  );
  const [selectedCurrency, setSelectedCurrency] = useState<CURRENCY_TYPE | null>(null);
  const [showApplicationError, setShowApplicationError] = useState(false);
  const [pageLeaveDialogProps, setPageLeaveDialogProps] = useState<null | {
    save: () => unknown;
    leave: () => unknown;
    stay: () => unknown;
  }>(null);
  const { current: onLeaveListeners } = useRef<NewApplicationLeaveListener[]>([]);
  const { current: onSaveListeners } = useRef<OnSaveListener[]>([]);
  const safeSetState = useSafeSetState();
  const [formReady, setFormReady] = useState(false);

  const isPageReady =
    !!Array.isArray(naicsList) &&
    ((location.pathname === NEW_APPLICATION_PAGE.path && formReady) ||
      (location.pathname !== NEW_APPLICATION_PAGE.path && !loading));

  const formMethods = useForm<ApplicationToBEType>({
    shouldUnregister: false,
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const { sections, handleSectionOpen, handleSectionClose, resetSections } = useApplicationSections(
    formMethods.errors,
  );

  const handleChangeCurrency = () => {
    setCurrency(selectedCurrency!);
    setSelectedCurrency(null);
  };

  let noDraftMessage = false;

  function handleNavigationAttempt(
    navigation: {
      cancel: () => unknown;
      resume: () => unknown;
    },
    location: Location,
  ) {
    let canLeave: boolean | null = true;
    if (isPublicPage) {
      canLeave = true;
    }

    canLeave =
      !location.pathname.startsWith(NEW_APPLICATION_PAGE.path + '/') &&
      onLeaveListeners.some((fn) => fn())
        ? null
        : true;
    if (!canLeave) {
      setPageLeaveDialogProps({
        save: async () => {
          if (await onApplicationSave(true)) {
            setPageLeaveDialogProps(null);
            navigation.resume();
          }
        },
        stay: () => {
          setPageLeaveDialogProps(null);
          navigation.cancel();
        },
        leave: () => {
          setPageLeaveDialogProps(null);
          navigation.resume();
        },
      });
    }

    return canLeave;
  }

  const beforeUnloadListener = (e: BeforeUnloadEvent) => {
    if (isPublicPage) {
      return null;
    }

    if (onLeaveListeners.every((fn) => !fn())) {
      return null;
    }
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = intl.formatMessage({ id: 'common.unsaved_changes' }); // in all modern browsers this message will be ignored

    return e.returnValue;
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  React.useEffect(() => {
    if (application?.application && !application.application.isDraft) {
      setHappyEnd(true);
    }
  }, [application?.application.isDraft]);

  React.useEffect(() => {
    setCurrency((application?.application.currency || defaultCurrency.name) as CURRENCY_TYPE);
  }, [application?.application.currency]);

  const customValidation = React.useCallback(() => {
    const application = formMethods.getValues();
    if (!application.applicant) {
      return { valid: true };
    }
    const applicationWithUpdates = application;
    const { businessType } = applicationWithUpdates.applicant.entity ?? {};
    const isPartnership = businessType === 'Partnership';

    if (isPartnership) {
      const individualParties = applicationWithUpdates.parties?.filter?.(
        (p) => p.entity.isBusiness === false,
      );
      return (individualParties?.length || 0) < 2
        ? {
            valid: false,
            message: intl.formatMessage({ id: 'application.partnership_colessees_error' }),
          }
        : {
            valid: true,
          };
    }

    return { valid: true };
  }, []);

  const cleanApplicationState = () => {
    if (formReady) {
      setFormReady(false);
    }
    resetApplication();
    resetSections();
    setCurrency((application?.application.currency || defaultCurrency.name) as CURRENCY_TYPE);
    setHappyEnd(false);
    setShowApplicationError(false);
    formMethods.reset();
    setTimeout(() => {
      // need this ugly timeout to let form to be reset
      // so we have initial render with proper default and form data
      safeSetState(setFormReady, [true]);
    }, 500);
  };

  React.useEffect(() => {
    if (!isPublicPage) {
      prepareApplicationForm();
    } else if (routeParams.vendorSiteUrl) {
      preparePublicApplicationForm(routeParams.vendorSiteUrl, routeParams?.userSiteUrl);
    }
  }, []);

  React.useEffect(() => {
    cleanApplicationState();
    return () => {
      cleanApplicationState();
    };
  }, []);

  const loadApplication = (applicationUuid: string) => {
    return getDraftApplication(applicationUuid, routeParams).then((loaded) => {
      if (!loaded) {
        if (isPublicPage) {
          history.push(SIGN_IN_PAGE.path);
        } else {
          setShowApplicationError!(true);
        }
      }
    });
  };

  const onApplicationSave = async (isNavigation?: boolean) => {
    const data = { ...formMethods.getValues() };
    const dataToSend = prepareApplicationDataToSend(
      data,
      currency,
      application?.application?.applicationName,
      application?.applicant.uuid,
    );

    let applicationUuid: string | undefined | null | boolean = application?.application.uuid;
    setBackdropLoader(true);
    try {
      if (applicationUuid) {
        await updateApplication({
          application: dataToSend,
          applicationUuid,
          routeParams,
          noDraftMessage,
        });
        await Promise.all(onSaveListeners.map(async (fn) => await fn(applicationUuid as string)));
        setBackdropLoader(false);
        noDraftMessage = false;
      } else {
        applicationUuid = await createApplication({ application: dataToSend, routeParams });
        if (applicationUuid) {
          await Promise.all(onSaveListeners.map(async (fn) => await fn(applicationUuid as string)));
          const applicationPath = generatePath(EDIT_DRAFT_APPLICATION_PAGE.path, {
            applicationUuid,
          });
          if (!isNavigation) {
            history.replace(
              isPublicPage ? getPublicRoute(applicationPath, routeParams) : applicationPath,
            );
          }
        }
        setBackdropLoader(false);
        noDraftMessage = false;
      }
    } catch {
      return null;
    }

    return applicationUuid as string;
  };

  const onApplicationSubmit = async () => {
    const valid = await formMethods.trigger();

    if (valid) {
      noDraftMessage = true;
      const applicationUuid = await onApplicationSave();
      setBackdropLoader(true);
      if (applicationUuid) {
        await submitApplication({ applicationUuid, routeParams });
        setBackdropLoader(false);
        return true;
      }
    }

    return false;
  };

  const newApplicationProviderValues: NewApplicationContextProps = {
    applicationUuid: application?.application.uuid,
    userUuid,
    routeParams: routeParams,
    loadApplication,
    isNewApplicationPageReady: isPageReady,
    vendorReps,
    customValidation,
    currency,
    changeCurrency: setSelectedCurrency,

    sections,
    handleSectionOpen,
    handleSectionClose,

    submit: onApplicationSubmit,
    save: onApplicationSave,

    subscribeOnSave: (fn) => {
      onSaveListeners.push(fn);
      return () => onSaveListeners.splice(onSaveListeners.indexOf(fn), 1);
    },

    hasChanges: () => {
      const onLeaveChanges = onLeaveListeners.some((fn) => fn());
      const userUuidChanges =
        (application?.application.userUuid || userUuid) !==
        (formMethods.getValues('application.userUuid') || userUuid);

      return onLeaveChanges || (userUuidChanges && !isPublicPage);
    },
    subscribeOnPageLeave: (fn) => onLeaveListeners.push(fn),
    unSubscribeOnPageLeave: (fn) => onLeaveListeners.splice(onLeaveListeners.indexOf(fn), 1),
  };

  React.useEffect(() => {
    if (location.pathname === NEW_APPLICATION_PAGE.path) {
      cleanApplicationState();
    }
  }, [location.pathname]);

  if (showApplicationError) {
    return <ApplicationError />;
  }

  if (happyEnd && application?.application.uuid) {
    let fromApplication = false;

    if (!application.quote.quoteInfo) {
      fromApplication = true;
    }

    return (
      <HappyEnd
        isPublicPage={isPublicPage}
        header={intl.formatMessage({ id: 'application.happy_end_header' })}
        text={intl.formatMessage({ id: 'application.happy_end_text' })}
        fromApplication={fromApplication}
      />
    );
  }

  return (
    <>
      {isSiteApplicationDisabled && (
        <div className={classes.textAlignCenter}>
          {intl.formatMessage({ id: 'quote.site_is_not_active' })}
        </div>
      )}

      {!isSiteApplicationDisabled && (
        <FormProvider {...formMethods}>
          {backdropLoader && (
            <Backdrop open className={classes.backdropLoader}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <form noValidate>
            <Dropzone>
              <NewApplicationContext.Provider value={newApplicationProviderValues}>
                <Loading isOpen={loading || !isPageReady} />
                {isPublicPage && (
                  <Typography variant="h5" className={classes.headerTitle}>
                    {intl.formatMessage({ id: 'application.create_application' })}
                  </Typography>
                )}
                <Switch>
                  {routesArray.map((routePrefix) => {
                    return [
                      <Route
                        exact
                        key={routePrefix + EDIT_DRAFT_APPLICATION_PAGE.path}
                        path={routePrefix + EDIT_DRAFT_APPLICATION_PAGE.path}
                        render={(routeProps) => (
                          <NewApplicationSections {...routeProps} application={application} />
                        )}
                      />,
                      <Route
                        exact
                        key={routePrefix + NEW_APPLICATION_PAGE.path}
                        path={routePrefix + NEW_APPLICATION_PAGE.path}
                        render={(routeProps) => (
                          <NewApplicationSections {...routeProps} application={application} />
                        )}
                      />,
                    ];
                  })}
                </Switch>

                <ReactRouterPause handler={handleNavigationAttempt} when />

                <AlertModal
                  data-cy="change-currency-modal"
                  isOpen={!!selectedCurrency}
                  onSubmit={handleChangeCurrency}
                  onClose={() => setSelectedCurrency(null)}
                  title={intl.formatMessage({ id: 'applicant.currency_question' })}
                >
                  <Typography>
                    <FormattedMessage id="applicant.currency_question_description" />
                  </Typography>
                </AlertModal>

                <OnLeavePopup
                  open={!!pageLeaveDialogProps}
                  leave={pageLeaveDialogProps?.leave}
                  cancel={pageLeaveDialogProps?.stay}
                  saveAndLeave={pageLeaveDialogProps?.save}
                />
              </NewApplicationContext.Provider>
            </Dropzone>
          </form>
        </FormProvider>
      )}
    </>
  );
}

export default NewApplication;
