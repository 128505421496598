import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/broker';
const api = getApi(BACKEND);

export const getBrokersVendorsList = async (queryString: string) => {
  return await api.get<{ rows: VendorListItem[]; count: number }>(
    `/vendor-list${queryString ? '?' : ''}${queryString}`,
  );
};

export const getBrokersUsersList = async (queryString: string) => {
  return await api.get<{ rows: VendorUserListItem[]; count: number }>(
    `/user-list${queryString ? '?' : ''}${queryString}`,
  );
};

export const loginAsVendorUser = async (userUuid: string) => {
  return await api.post(`/login-as-user/${userUuid}`);
};

export const getBrokerContactsInfo = async (brokerUuid: string) => {
  return await api.get<ContactsStoreType>(`/${brokerUuid}/contacts`);
};
