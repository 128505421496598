import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import {
  signInUser,
  logoutUser,
  SignInUserType,
  changeUserLanguage,
  fetchUserData as fetchUserDataRequest,
  resetPassword,
  changeUserPassword,
  changeUserEmail,
  updateUserProfile as updateUserInfo,
  switchAccount,
} from '../../apis/users';
import { actions as toastActions } from '../toast';
import { getIntl } from '../../utils/language';
import { actions as settings } from '../settings';

const initialState: AppStoreType['user'] = {};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserData: (state: AppStoreType['user'], action: PayloadAction<AppStoreType['user']>) => {
      Object.assign(state, action.payload);
    },
    updateUserError: (
      state: AppStoreType['user'],
      action: PayloadAction<UserStoreType['error']>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const actions = slice.actions;

export const validateUser =
  (user: SignInUserType) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await signInUser(user);
    } catch (err) {
      const { message, data } = err.response.data; // TODO server side translation
      if (data.errorType === 'passExpired') {
        dispatch(actions.updateUserError(''));

        return Promise.reject(data);
      }

      if (err.response.data.code === 409) {
        dispatch(actions.updateUserError(intl.formatMessage({ id: 'common.select_role' })));
      } else {
        dispatch(actions.updateUserError(message));
      }
      return Promise.reject(err.response.data);
    }
  };

export const switchUserAccount = (vendorUuid: string) => async (dispatch: Dispatch<unknown>) => {
  try {
    await switchAccount(vendorUuid);
    const userData = await fetchUserDataRequest();
    dispatch(actions.updateUserData(userData.data));
  } catch (err) {
    const { message, data } = err.response.data; // TODO serverside translation
    if (data.errorType === 'passExpired') {
      dispatch(actions.updateUserError(''));
      return Promise.reject(data);
    }

    dispatch(actions.updateUserError(message));
    return Promise.reject(err.response.data);
  }
};

export const logout = () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
  const intl = getIntl(getState);
  try {
    await logoutUser();
  } catch (err) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'user.failed_logout' }),
    };
    dispatch(toastActions.open(toastOptions));

    return Promise.reject();
  }
};

export const changeLanguage =
  (languageType: LanguageType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      if (getState().user.uuid) {
        await changeUserLanguage(languageType);
        const userData = await fetchUserDataRequest();
        dispatch(actions.updateUserData(userData.data));
      }
      dispatch(settings.changeLanguage(languageType.code as Languages));
    } catch (err) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.failed_switch_language' }),
      };
      dispatch(toastActions.open(toastOptions));

      return Promise.reject();
    }
  };

export const updateUserProfile =
  (userObj: EditUserType) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await updateUserInfo(userObj);
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.user_update_error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.user_update_success' }),
    };

    dispatch(toastActions.open(toastOptions));
  };

export const fetchUserData =
  () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const userData = await fetchUserDataRequest();
      dispatch(actions.updateUserData(userData.data));
      dispatch(settings.changeLanguage(userData.data.defaultLanguage as Languages));
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };

      return dispatch(toastActions.open(toastOptions));
    }
  };

export const validateResetPassword =
  (email: string, type?: string | undefined) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await resetPassword(email, type);
    } catch (err) {
      const { status } = err.response;
      let message = '';

      if (err.response.data.code === 409) {
        dispatch(actions.updateUserError(intl.formatMessage({ id: 'common.select_role' })));
      } else {
        if (status === 403) {
          message = intl.formatMessage({ id: 'user.cannot_reset_password' });
        } else {
          message = intl.formatMessage({ id: 'common.something_went_wrong' });
        }
        dispatch(actions.updateUserError(message));
      }

      return Promise.reject(err.response.data);
    }
  };

export const validateChangeEmail =
  (token: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await changeUserEmail(token);
    } catch (err) {
      const { status } = err.response;
      let message = '';
      if (status === 403) {
        message = intl.formatMessage({ id: 'user.token_expired_error_request_confirmation' });
      } else {
        message = intl.formatMessage({ id: 'common.something_went_wrong' });
      }

      const toastOptions = {
        toastType: 'error' as const,
        message,
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.updateUserError(message));

      return Promise.reject();
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.email_changed_success' }),
    };
    dispatch(toastActions.open(toastOptions));
  };

export const validateChangePassword =
  (newPassword: string, token: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await changeUserPassword({ newPassword, token });

      dispatch(actions.updateUserError(''));
    } catch (err) {
      const { status } = err.response;
      let message = '';
      if (status === 403) {
        message = intl.formatMessage({ id: 'user.token_expired_reset_password' });
      } else {
        message =
          err.response?.data?.message ?? intl.formatMessage({ id: 'common.something_went_wrong' });
      }
      dispatch(actions.updateUserError(message));

      return Promise.reject();
    }
  };

export const validateChangeExpiredPassword =
  (newPassword: string, email: string, prevPassword: string, type?: string | undefined) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await changeUserPassword({ newPassword, prevPassword, email, type });

      dispatch(actions.updateUserError(''));
    } catch (err) {
      const { status } = err.response;
      let message = '';
      if (status === 403) {
        message = intl.formatMessage({ id: 'user.token_expired_reset_password' });
      } else {
        message =
          err.response?.data?.message ?? intl.formatMessage({ id: 'common.something_went_wrong' });
      }
      dispatch(actions.updateUserError(message));

      return Promise.reject(err.response.data);
    }
  };

export default slice.reducer;
