import { io, Socket } from 'socket.io-client';
import { getBackendUrl } from '../utils/getBackendUrl';
import { SIGN_IN_PAGE } from '../constants/navigations';

type SubscribeHandler<T extends IoResponse | IoProgress> = (data: T) => unknown;
export type IoResponse = { uuid: string };
export type IoProgress = IoResponse & {
  type?: 'socket' | 'buffer';
  bytesRead: number;
  bytesLeft: number;
  progress: number;
};

export type UpdateHandler = SubscribeHandler<IoResponse>;
export type ProgressHandler = SubscribeHandler<IoProgress>;

let socket: Socket | null = null;
let id = 0;

export type UpdateSubscribeParams<T extends IoResponse = IoResponse> = {
  entity: 'application' | 'document';
  filter: 'list' | string;
  handler: SubscribeHandler<T>;
};

export type ProgressSubscribeParams<T extends IoProgress = IoProgress> = {
  entity: 'progress';
  filter: string;
  handler: SubscribeHandler<T>;
};

type SubscribeParams = UpdateSubscribeParams | ProgressSubscribeParams;

let subscriptions: Array<
  {
    id: number;
  } & SubscribeParams
> = [];

const addSubscription = (params: SubscribeParams, id: number) => {
  subscriptions.push({
    id,
    ...params,
  });
};

const removeSubscription = (id: number) => {
  subscriptions = subscriptions.filter((s) => s.id !== id);
};

const wsPath = getBackendUrl()!.split('//')?.[1]?.split('/')?.[0];

export type OnProgressFn = (progress: IoResponse) => unknown;

export const initWS = () => {
  if (!socket) {
    socket = io(wsPath, {
      transports: ['websocket'],
      path: '/api/socket.io',
    });

    socket.on('connect', () => {
      socket?.on('session-expired', () => {
        location.href = `${SIGN_IN_PAGE.path}?redirect=${location.pathname}`;
      });
      subscriptions.forEach((s) => {
        socket!.emit('subscribe', s.entity, s.filter);
        socket!.on(s.entity, (args) => {
          if (s.filter !== 'list' && s.filter !== args.uuid) {
            return;
          } else {
            s.handler(args);
          }
        });
      });
    });
  }
};

export const subscribe = <T extends IoResponse>({
  entity,
  handler,
  filter,
}: {
  entity:
    | 'application'
    | 'document'
    | 'progress'
    | 'amendment'
    | 'applicationDocument'
    | 'userDeactivated';
  filter: 'list' | string;
  handler: SubscribeHandler<T>;
}) => {
  const subscriptionId = ++id;
  addSubscription(
    {
      entity,
      handler,
      filter,
    } as SubscribeParams,
    subscriptionId,
  );

  if (socket?.connected) {
    socket!.emit('subscribe', entity, filter);
    socket!.on(entity, (args: T) => {
      if (filter !== 'list' && filter !== args.uuid) {
        return;
      } else {
        handler(args);
      }
    });
  }

  return () => {
    if (subscriptions.every((s) => s.entity !== entity)) {
      socket?.off(entity);
    }
    unsubscribe(entity, filter, subscriptionId);
  };
};

export const unsubscribe = (
  entity:
    | 'application'
    | 'document'
    | 'progress'
    | 'amendment'
    | 'applicationDocument'
    | 'userDeactivated',
  filter: 'list' | string,
  id: number,
) => {
  removeSubscription(id);
  if (!socket?.connected) {
    return false;
  }

  socket.emit('unsubscribe', entity, filter);
};
