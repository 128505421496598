import { differenceInYears } from 'date-fns';
import { DEFAULT_ADULT_AGE, DEFAULT_CHILD_AGE, isSinValid } from '../utils/common';

export const getAge = (dateOfBirth: string | Date | null, sin?: string) => {
  if (!dateOfBirth && sin) {
    const plainSin = sin.replaceAll('-', '').replaceAll('_', '');

    return isSinValid(plainSin) ? DEFAULT_ADULT_AGE : DEFAULT_CHILD_AGE;
  }
  try {
    if (!dateOfBirth) {
      return 0;
    }
    const fDateOfBirth = new Date(dateOfBirth);

    return differenceInYears(new Date(), fDateOfBirth);
  } catch {
    return 0;
  }
};
