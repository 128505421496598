import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { validateChangePassword } from '../../store/user';
import { Copyright } from '../../components/common/Copyright';
import Link from '../../components/common/link';
import { useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { getValidationArray } from './utils';
import { useStyles } from './style';
import { SignInToolbar } from '../../components/common/SignInToolbar';

type ChangePasswordFormValues = {
  newPassword: string;
  repeatPassword: string;
};

type UrlParams = {
  token: string;
};

const userErrorSelector = (state: AppStoreType) => state.user;

export default function ChangePassword() {
  const classes = useStyles();
  const intl = useIntl();
  const { token } = useParams<UrlParams>();
  const { error } = useSelector<AppStoreType, ReturnType<typeof userErrorSelector>>(
    userErrorSelector,
  );
  const [newPassVisible, setNewPassVisible] = React.useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formMethods = useForm<ChangePasswordFormValues>({
    defaultValues: {
      newPassword: '',
      repeatPassword: '',
    },
  });
  const { register, handleSubmit, reset, errors, watch } = formMethods;

  const newPassword = watch('newPassword');
  const submit = async (data: ChangePasswordFormValues) => {
    const { newPassword } = data;
    try {
      await dispatch(validateChangePassword(newPassword, token));
      reset();
      return history.push('/sign-in');
    } catch {
      return;
    }
  };

  const validationArray = React.useMemo(() => getValidationArray(intl), [intl]);

  return (
    <>
      <SignInToolbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SettingsBackupRestoreIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'common.change_your_password' })}
          </Typography>
          <FormProvider {...formMethods}>
            <form className={classes.form} onSubmit={handleSubmit(submit)} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  validate: (input) => {
                    if (!input) {
                      return intl.formatMessage({ id: 'common.password_not_empty' });
                    }
                    if (validationArray.some((v) => v.isInvalid(input))) {
                      return intl.formatMessage({ id: 'common.more_secured_password_needed' });
                    }

                    return undefined;
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setNewPassVisible(!newPassVisible)}
                        edge="end"
                      >
                        {newPassVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="newPassword"
                label={intl.formatMessage({ id: 'common.new_password' })}
                type={newPassVisible ? 'text' : 'password'}
                id="newPassword"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
              />
              <div>
                {validationArray.map((v) => {
                  const isInvalid = v.isInvalid(newPassword);
                  return (
                    <FormHelperText className={classes.validationWrapper} key={v.text}>
                      <FiberManualRecordIcon
                        className={clsx(classes.dot, {
                          [classes.dotSuccess]: !isInvalid,
                          [classes.dotFailed]: isInvalid,
                        })}
                      />
                      <span
                        className={clsx({
                          [classes.validationTextSuccess]: !isInvalid,
                        })}
                      >
                        {v.text}
                      </span>
                    </FormHelperText>
                  );
                })}
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  validate: (value) => {
                    if (!value) {
                      return intl.formatMessage({ id: 'common.password_not_empty' });
                    } else if (value !== newPassword) {
                      return intl.formatMessage({ id: 'common.password_not_match' });
                    }
                  },
                })}
                name="repeatPassword"
                label={intl.formatMessage({ id: 'common.confirm_new_password' })}
                type={confirmPassVisible ? 'text' : 'password'}
                id="repeatPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() => setConfirmPassVisible(!confirmPassVisible)}
                        edge="end"
                      >
                        {confirmPassVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.repeatPassword}
                helperText={errors.repeatPassword?.message}
              />
              {error && (
                <Typography variant="subtitle2" color="error">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(submit)}
              >
                {intl.formatMessage({ id: 'common.change_password' })}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/sign-in">{intl.formatMessage({ id: 'common.sign_in' })}</Link>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
