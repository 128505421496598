import React, { useContext } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NewApplicationContext, { NewApplicationContextProps } from './new-application.context';
import ApplicationEntityForm from '../../components/common/ApplicationEntityForm';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { getDatesDifferenceInYears } from '../../utils/date';
import { toFlatPropertyMap } from '../../utils/common';
import get from 'lodash/get';
import { cloneDeep } from 'lodash';

export default function ColesseeSection({
  defaultData,
  applicantData,
}: {
  defaultData: EntityApplicationRoleToBEType[];
  applicantData: EntityApplicationRoleToBEType | null;
}) {
  const intl = useIntl();
  const classes = useStyles();
  const formMethods = useFormContext();
  const {
    control,
    getValues,
    formState: { dirtyFields },
  } = formMethods;
  const { subscribeOnPageLeave, unSubscribeOnPageLeave, customValidation } =
    useContext<NewApplicationContextProps>(NewApplicationContext);
  const {
    fields: parties,
    append,
    remove,
  } = useFieldArray<EntityApplicationRoleToBEType, 'formId'>({
    control,
    name: 'parties',
    keyName: 'formId',
  });
  React.useEffect(() => {
    formMethods.register('customValidation.parties', {
      validate: () => customValidation({ parties: getValues('parties') ?? [] })?.message,
    });
  }, []);

  const watchedApplicantInBusiness = useWatch({
    control,
    defaultValue: getValues('applicant.entity.inBusinessSince'),
    name: 'applicant.entity.inBusinessSince',
  });
  const watchedApplicantBusinessType = useWatch({
    control,
    defaultValue: getValues('applicant.entity.businessType'),
    name: 'applicant.entity.businessType',
  });

  React.useEffect(() => {
    formMethods.setValue('parties', []);
    if (defaultData) {
      const sameAsMap: { [key: string]: string } = {};
      if (applicantData?.entity.contact?.uuid) {
        sameAsMap[applicantData.entity.contact.uuid] = 'applicant.entity.contact.';
      }
      defaultData.forEach((originalParty, i) => {
        const party = cloneDeep(originalParty);
        if (party.entity?.contact?.uuid && party.entity.contact.uuid in sameAsMap) {
          party.entity.contact.sameAs = sameAsMap[party.entity.contact.uuid];
        }
        if (party.entity.isBusiness === false && party?.entity.uuid) {
          sameAsMap[party.entity.uuid] = `party.${i}.entity.`;
        }
        if (party?.entity.contact?.uuid) {
          sameAsMap[party.entity.contact.uuid] = `party.${i}.entity.contact.`;
        }

        append(party);
      });
    }
  }, []);

  const addParty = () => {
    append({
      role: 'Co-lessee',
      entity: {
        isBusiness: true,
        businessType: 'Corporation',
        contact: {
          isBusiness: false,
          businessType: null,
        },
      },
    });
  };

  const deleteParty = async (partyIndx: number) => {
    remove(partyIndx);
  };

  const minColesseeNumber = React.useMemo(() => {
    if (applicantData?.entity.businessType === 'Partnership') {
      return 2;
    }
    if (
      applicantData?.entity.businessType === 'Corporation' &&
      getDatesDifferenceInYears(new Date(), new Date(applicantData?.entity.inBusinessSince ?? '')) <
        5
    ) {
      return 1;
    }

    return 0;
  }, [applicantData]);

  const validationState = React.useMemo(() => {
    return customValidation({ parties: getValues('parties') ?? [] });
  }, [watchedApplicantBusinessType, watchedApplicantInBusiness, defaultData]);

  const leaveListener = React.useCallback(() => {
    const updatedDirtyFields = dirtyFields?.parties?.map((p: EntityApplicationRoleType) => ({
      ...p,
      entity: {
        naicsSfId: true,
        sin: true,
        country: true,
        ...p.entity,
      },
    }));
    const flatKeys = Reflect.ownKeys(toFlatPropertyMap({ parties: updatedDirtyFields }));
    const data = getValues();
    const diff = flatKeys
      .filter(
        (key) => !(typeof key === 'string' && (key.endsWith('.naics') || key.endsWith('.sameAs'))),
      )
      .map((key) => {
        if ((typeof key === 'string' && key.endsWith('.entity')) || key === 'parties') {
          return null;
        }
        let prevVal = get({ parties: defaultData }, key);
        if (!prevVal) {
          prevVal = null;
        }
        let currentVal = get(data, key);
        if (!currentVal) {
          currentVal = null;
        }
        if ((key as string).endsWith?.('.country')) {
          return prevVal ? prevVal != currentVal : null;
        }

        return prevVal != currentVal ? key : null;
      })
      .filter(Boolean);

    return !!diff.length || defaultData.length !== (getValues('parties') ?? []).length;
  }, [dirtyFields, defaultData, getValues]);

  React.useEffect(() => {
    subscribeOnPageLeave(leaveListener);
    return () => {
      unSubscribeOnPageLeave(leaveListener);
    };
  }, [leaveListener]);

  return (
    <>
      {parties.map((party, i, array) => {
        return (
          <ApplicationEntityForm
            sameAsName={intl.formatMessage({ id: 'applicant.co_lessee' }) + ' ' + (i + 1)}
            key={party.uuid || i}
            path={`parties.${i}.`}
            defaultEntity={party.entity ?? null}
            role={party.role}
            onDelete={array.length <= minColesseeNumber ? undefined : () => deleteParty(i)}
            title={intl.formatMessage({ id: 'applicant.co_lessee' }) + ' ' + (i + 1)}
          />
        );
      })}
      <Container>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Button
              className={classes.buttonMarginTop}
              data-cy="add-colessee"
              variant="outlined"
              color="primary"
              onClick={addParty}
            >
              + {intl.formatMessage({ id: 'applicant.add_co_lessee' })}
            </Button>
          </Grid>
          {!!validationState.message && (
            <Grid item xs={12} sm={9}>
              <Alert className={classes.colesseeAlert} severity="warning">
                {validationState.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
